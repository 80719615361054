@import
"_utils.scss",
"_mixins.scss",
"_fonts.scss",
"_colors.scss",
"_variables.scss"
;

body{
    font-size: 26px;
    background-color: $blue;
    font-family: 'ibm_plex', serif;
    font-weight: 900;
    color: white;
    text-align: center;

    .container{
        height: 90vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1{
            margin: 1rem;
            padding: 0;
        }

        h2{
            margin: 0;
            padding: 0;
        }

        p{
            margin: 1rem;
            padding: 0;
        }

        a{
            text-decoration: none;

            &:visited{
                color: inherit;
            }
        }
    }
}